import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import Button from "react-bootstrap/Button"
import CardBackImage from "../images/cardback-img"

const StyledContainer = styled.div`
  width: 32.5rem;
  height: auto;
  margin: 1rem auto;
  background: var(--shade);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    color: var(--gold);
  }
  @media (min-width: 576px) {
    width: 95%;
    min-height: 90%;
    margin: 2.5% auto;
    padding: 1rem;
  }
`

const StyledHeading = styled.div`
  width: 100%;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
`

const StyledInstructions = styled.div`
  width: 90%;
  margin: 2rem auto;
  h4 {
    color: var(--gold);
    font-size: 1.2rem;
    text-align: center;
  }
  p {
    color: var(--gold);
    font-size: 1rem;
    text-align: justify;
    margin-top: 0;
  }
  @media (min-width: 1024px) {
    width: 50%;
  }
`

const StyledSpread = styled.div`
  width: 97.5%;

  margin: 0.5rem auto;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 0.5rem;
  @media (min-width: 576px) {
    grid-template-columns: 12rem 12rem 12rem;
    grid-template-rows: auto;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 2rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 15rem 15rem 15rem;
    gap: 3rem;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 22.5rem 22.5rem 22.5rem;
    gap: 3rem;
  }
  .gatsby-image-wrapper {
    width: 100px;
    height: auto;
    object-fit: scale-down;
    @media (min-width: 576px) {
      width: 12rem;
      height: auto;
    }
    @media (min-width: 768px) {
      width: 15rem;
      height: 25rem;
    }
    @media (min-width: 1024px) {
      width: 22.5rem;
      height: 40rem;
    }
  }
`
const StyledThemes = styled.div`
  width: 97.5%;

  margin: 0.5rem auto;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 0.25rem;
  @media (min-width: 576px) {
    grid-template-columns: 12rem 12rem 12rem;
    grid-template-rows: auto;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 2rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 15rem 15rem 15rem;
    gap: 3rem;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 22.5rem 22.5rem 22.5rem;
    gap: 4rem;
  }
`

export default function DailyReading(props) {
  const [loading, setLoading] = useState(true)
  const [loveClick, setLoveClick] = useState(false)
  const [youClick, setYouClick] = useState(false)
  const [careerClick, setCareerClick] = useState(false)

  const handleClick = () => {
    setLoading(loading)
    navigate("/readingresult/")
  }

  return (
    <>
      <StyledContainer>
        <StyledHeading>
          <h3>Daily Tarot Reading</h3>
        </StyledHeading>
        <StyledSpread>
          <Button
            key={"love"}
            onClick={() => setLoveClick(true)}
            style={{ borderRadius: `2rem` }}
          >
            <CardBackImage style={{ borderRadius: `2rem` }} />
          </Button>

          <Button
            key={"you"}
            onClick={() => setYouClick(true)}
            style={{ borderRadius: `2rem` }}
          >
            <CardBackImage style={{ borderRadius: `2rem` }} />
          </Button>

          <Button
            key={"career"}
            onClick={() => setCareerClick(true)}
            style={{ borderRadius: `2rem` }}
          >
            <CardBackImage style={{ borderRadius: `2rem` }} />
          </Button>
        </StyledSpread>
        <StyledThemes>
          {!loveClick ? (
            <h4 style={{ color: `var(--gold)` }}>Love</h4>
          ) : (
            <h4 style={{ color: `var(--lpurple)` }}>Love</h4>
          )}
          {!youClick ? (
            <h4 style={{ color: `var(--gold)` }}>You</h4>
          ) : (
            <h4 style={{ color: `var(--lpurple)` }}>You</h4>
          )}
          {!careerClick ? (
            <h4 style={{ color: `var(--gold)` }}>Career</h4>
          ) : (
            <h4 style={{ color: `var(--lpurple)` }}>Career</h4>
          )}
        </StyledThemes>
        <StyledInstructions>
          <p>
            Tap or click on each card above to change the colour of its theme to
            begin shuffling the decks. All three decks must be shuffled to
            unlock the button and access your reading.
          </p>
        </StyledInstructions>
        {!loveClick || !youClick || !careerClick ? (
          <Button
            className="w-50  mx-auto"
            type="button"
            disabled={loading}
            style={{ border: `thin solid var(--gold)` }}
          >
            Click Each Card to Proceed
          </Button>
        ) : (
          <Button
            className="w-50  mx-auto"
            type="button"
            onClick={handleClick}
            style={{ border: `thin solid var(--gold)` }}
          >
            Click Here For Your Reading
          </Button>
        )}
      </StyledContainer>
    </>
  )
}
