import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const CardStyled = styled.div`
  width: 100%;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: auto;
    height: auto;
    margin: 0 auto;
    border-radius: 1rem;
  }
`

const CardBackImage = () => {
  const card = useStaticQuery(graphql`
    {
      placeholderImage: file(relativePath: { eq: "atttarotcard.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
    }
  `)

  if (!card?.placeholderImage?.childImageSharp?.gatsbyImageData) {
    return <div>Picture not found</div>
  }

  return (
    <CardStyled>
      <GatsbyImage
        image={card.placeholderImage.childImageSharp.gatsbyImageData}
        alt="ask the tarot back of card"
      />
    </CardStyled>
  )
}

export default CardBackImage
