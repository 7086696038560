import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import DailyReading from "../components/dailyreading"

const StyledContainer = styled.div`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
`

export default function DailyReadPage() {
  return (
    <>
      <SEO title="Daily Reading" />
      <StyledContainer>
        <DailyReading />
      </StyledContainer>
    </>
  )
}
